/* ---------------------------------
chiedi-esperto
--------------------------------- */

import React from "react";
import { ContactForm } from "../../components/ContactForm";
import { Head } from "../../components/Head";
import BasePage from "../../templates/BasePage";

// chiedi-esperto
export default () => {
  return (
    <BasePage title={"Chiedi a un esperto"}>
      <Head title="Chiedi a un esperto" />
      <ContactForm enableSupportText />
    </BasePage>
  );
};
